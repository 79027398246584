import { genericError } from './ui/Error'
import * as Sentry from '@sentry/react'

export default function ErrorBoundary({ children, beforeCapture = null }) {

    return (
        <Sentry.ErrorBoundary fallback={genericError}
            beforeCapture={beforeCapture}
        >
            {children}
        </Sentry.ErrorBoundary>
    )
}
